var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports.test = function () {
  return typeof _global.queueMicrotask === "function";
};

exports.install = function (func) {
  return function () {
    _global.queueMicrotask(func);
  };
};

export default exports;
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports.test = function () {
  if (_global.setImmediate) {
    // we can only get here in IE10
    // which doesn't handel postMessage well
    return false;
  }

  return typeof _global.MessageChannel !== "undefined";
};

exports.install = function (func) {
  var channel = new _global.MessageChannel();
  channel.port1.onmessage = func;
  return function () {
    channel.port2.postMessage(0);
  };
};

export default exports;
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports.test = function () {
  return "document" in _global && "onreadystatechange" in _global.document.createElement("script");
};

exports.install = function (handle) {
  return function () {
    // Create a <script> element; its readystatechange event will be fired asynchronously once it is inserted
    // into the document. Do so, thus queuing up the task. Remember to clean up once it's been called.
    var scriptEl = _global.document.createElement("script");

    scriptEl.onreadystatechange = function () {
      handle();
      scriptEl.onreadystatechange = null;
      scriptEl.parentNode.removeChild(scriptEl);
      scriptEl = null;
    };

    _global.document.documentElement.appendChild(scriptEl);

    return handle;
  };
};

export default exports;